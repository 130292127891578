body {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.card.custom-radius {
  border-radius: 1rem;
}

.card-bkgd {
  width: 34vmin; 
  height: 34vmin; 
  border-radius: 50%;
}

@media (max-width: 700px) {
  .card-bkgd {
    width: 70vmin; 
    height: 70vmin; 
  }
}

@media (max-width: 800px) {ƒ
  .card-bkgd {
    width: 40vmin; 
    height: 40vmin; 
  }
}

.fixed-top {
  position: fixed;
  top: 0;
  z-index: 1020;
  width: 100%;
  min-height: 102px;
}

.splash-title {
  position: absolute;
  top: 15vh;
}

@media (max-width: 774px) {
  .splash-title {
    margin: 100px;
    position: absolute;
    top: 30%;
  }
}

@media (max-width: 420px) {
  .splash-title {
    margin: 1px;
    position: absolute;
    /* top: 30%; */
  }
}

.splash-height {
  min-height: 36rem;
}

.calendly-parent {
  min-height: 855px;
}

.calendly-inline-widget {
  min-width: 320px;
  min-height: 750px;
}

@media (max-width: 1273px) {
  .calendly-parent {
    min-height: 1000px;
  }
  .calendly-inline-widget {
    min-height: 1100px;
  }
}

.calendly-height {
  min-height: 36rem;
}

.header-footer-container {
  margin-left: 24px;
  margin-right: 24px;
}

@media (min-width: 451px) {
  .main-section {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 450px) {
  .main-section {
    margin-left: 2%;
    margin-right: 2%;
  }
}

p.logo-spacing {
  margin-bottom: 3px;
}

.socials-links {
  text-decoration: none;
}

.logo-footer {
  transform: scale(0.3);
}

.fit-logo {
  max-width: 150px;
}

@media (max-width: 450px) {
  .chain {
    background-image: linear-gradient(to right, rgba(11, 29, 53, 0.85), rgba(11, 29, 53, 0.5)), url(./static_assets/digital_chain_small.jpg);
  
    /* Set a specific height */
    min-height: 800px;
  
    /* Create the parallax scrolling effect */
    /* background-attachment: fixed; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
  }  
}

@media (min-width: 451px) {
  .chain {
    background-image: linear-gradient(to right, rgba(11, 29, 53, 0.85), rgba(11, 29, 53, 0.5)), url(./static_assets/digital_chain.jpg);

    /* Set a specific height */
    min-height: 1000px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
  }
}

.over-chain {
  height: 100%;
  width: 100%;
  color: #f1faee;
}

.spaced {
  margin-left: 1rem;
  margin-top: 0.2rem;
}

.about-me {
  /* Center and scale the image nicely */
  background-position: 75% 75%;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-me.pk {
  background-image: url(./static_assets/pk_headshot.jpg);
}

.about-me.rm {
  background-image: url(./static_assets/rm_headshot.jpg);
  background-position: 35% 75%;
}

.about-me.mc {
  background-image: url(./static_assets/mc_headshot.jpg);
  background-position: top left 15px;
}

.taxes {
  background-image: url(./static_assets/calc.jpg);
  
  /* Center and scale the image nicely */
  /* background-position: 75% 75%; */
  background-repeat: no-repeat;
  background-size: cover;
}

.footer {
  background-color: rgba(11, 29, 53, 0.85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.footer-header.clear-at-top {
  background-color: rgba(11, 29, 53, 0);
	animation-name: fadeOutHeader;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .3s;
  
}

@keyframes fadeOutHeader {
	0% {
		background-color: rgba(11, 29, 53, 0.85);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
	}
	100% {
		background-color: rgba(11, 29, 53, 0);
	}
}

.footer-header.shown-below-top {
  background-color: rgba(11, 29, 53, 0.85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
	animation-name: fadeInColor;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .3s;
}

@keyframes fadeInColor {
	0% {
		background-color: rgba(11, 29, 53, 0);
	}
	100% {
		background-color: rgba(11, 29, 53, 0.85);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
	}
}

.custom-active {
  color: #F1FAEE;

}

.footer-header a {
  color: #a8dadc;
  transition: color .15s ease-in-out;
  text-decoration: none;
}

.footer-header a.above-main-section {
  text-decoration: underline;
}

.footer-header a:hover {
  color: #f1faee;
}

.footer-header svg:hover {
  color: #f1faee;
}

.footer-header h5 {
  color: #a8dadc;
}

.footer-header h3 {
  color: #a8dadc;
}

.footer-header svg {
  color: #a8dadc;
}

.flex-equal > * {
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}

.bottom-buffer {
  margin-top: 8px;
}

.right-side {
  text-align: right;
}

.mo-margin {
  margin: 8px;
}

.social-icons {
  height: 24px;
  width: 24px;
  fill: #457b9d;
}

.socials-links:hover .social-icons {
  fill:#37627e;
}

.hide-at-top {
  opacity: 0;
	animation-name: fadeOutOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-duration: .15s;
}

@keyframes fadeOutOpacity {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.show-below-top {
  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .15s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.filler {
  flex-grow: 1;
}

.second-p {
  margin-top: 40px;
}

@media (max-width: 774px) {
  .hide-mobile {
    display: none;
  }
  .logo-medium {
    width: 80px;
  }

  .logo-xl {
    width: 200px;
  }
}

@media (max-width: 960px) {
  .hide-skinny {
    display: none;
  }
}

@media (min-width: 961px) {
  .hide-noskinny {
    display: none;
  }
}

@media (min-width: 775px) {
  .hide-nonmobile {
    display: none;
  }

  .logo-medium {
    width: 60px;
  }

  .logo-xl {
    width: 260px;
  }
}

.featured-nft-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 400px) {
  .art-card {
    width: 320px;
    height: 320px;
    border:rgba(0, 0, 0, 0.125) 1px solid;
    border-radius: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;
  
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-size: contain;
  
    animation-name: shrinkmobile;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .15s;
  }

  .art-card:hover {
    width: 330px;
    height: 330px;

    animation-name: growmobile;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .15s;
  }
  @keyframes growmobile {
    0% {
      width: 320px;
      height: 320px;
    }
    100% {
      width: 330px;
      height: 330px;
    }
  }
  
  @keyframes shrinkmobile {
    0% {
      width: 330px;
      height: 330px;
    }
    100% {
      width: 320px;
      height: 320px;
    }
  }
}

@media (min-width: 401px) {
  .art-card {
    width: 350px;
    height: 350px;
    border:rgba(0, 0, 0, 0.125) 1px solid;
    border-radius: 2rem;
    margin: 20px;
  
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-size: contain;
  
    animation-name: shrink;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .15s;
  }

  .art-card:hover {
    width: 370px;
    height: 370px;

    animation-name: grow;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .15s;
  }
  @keyframes grow {
    0% {
      width: 350px;
      height: 350px;
    }
    100% {
      width: 370px;
      height: 370px;
    }
  }
  
  @keyframes shrink {
    0% {
      width: 370px;
      height: 370px;
    }
    100% {
      width: 350px;
      height: 350px;
    }
  }
}

.art-card.yeti {
  background-image: url(./static_assets/Yeti.jpg);
}

.art-card.ape {
  background-image: url(./static_assets/BAYC.png);
}

.art-card.doge {
  background-image: url(./static_assets/BAKC.png);
}